@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
  font-feature-settings: "kern";
  -moz-font-feature-settings: "kern";
  font-synthesis: none;
}

body {
  line-height: 1.50;
}

p {
  padding-bottom: 1rem;
  line-height: 1.4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333333;
  line-height: 1.2;
  font-optical-sizing: auto;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.6rem;
}

h5,
h6 {
  font-size: 1.2rem;
}

h5 {
  margin-bottom: 0.4rem;
  font-weight: 500;
}

.small {
  font-size: 0.833rem;
  font-weight: 500;
  color: #b6b6b6;
  padding-bottom: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #ffffff;
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: #ffffff;
  fill: none;
  stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}